import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'

import { CloseIcon } from 'shared/icons'

import './closeButton.scss'

interface ICloseButton extends HTMLAttributes<HTMLButtonElement> {
	readonly onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const CloseButton: FC<ICloseButton> = ({ className, onClick }) => {
	return (
		<button onClick={onClick} className={clsx('close-button', className)}>
			<CloseIcon />
		</button>
	)
}
