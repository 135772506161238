import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)
export function getRange(date: dayjs.Dayjs = dayjs()) {
	const firstDayOfTheWeekIndex = 1
	const daysInTheWeek = 7
	const rangeStart = date.isoWeekday(firstDayOfTheWeekIndex)
	return new Array(daysInTheWeek).fill(0).map((_, i) => {
		const currentDate = rangeStart.add(i, 'day')
		return currentDate
	})
}
