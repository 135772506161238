import clsx from 'clsx'
import { FC } from 'react'

import { PlayerCard } from 'entities/playerCard'

import { sortPlayers } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IPlayer } from 'shared/types'

import './playersSquad.scss'

interface IPlayersSquad {
	readonly players: IPlayer[]
}

export const PlayersSquad: FC<IPlayersSquad> = ({ players }) => {
	const session = useSessionStore((state) => state.session)
	const squad = session ? sortPlayers(session.id, players) : players
	return (
		<div className="players-squad">
			{squad.map((player) => (
				<PlayerCard player={player} key={player.id} />
			))}
		</div>
	)
}
