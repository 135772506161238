import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useResize } from 'shared/lib/useResize'

interface ILoaderWorkoutDetail {}

export const LoaderWorkoutDetail: FC<ILoaderWorkoutDetail> = () => {
	const { LG_SCREEN } = useResize()
	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: '5px',
				position: 'relative',
			}}
		>
			<Skeleton width={300} height={30} style={{ marginBottom: 20 }} />
			<Skeleton
				width={100}
				height={20}
				count={2}
				style={{ marginBottom: 20 }}
			/>
			<Skeleton width={400} height={25} style={{ marginBlock: 20 }} />
			<Skeleton width={100} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={200} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={100} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={400} height={80} style={{ marginBottom: 20 }} />
			<Skeleton width={100} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={200} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={200} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={200} height={20} style={{ marginBottom: 20 }} />
			<Skeleton width={200} height={20} style={{ marginBottom: 20 }} />
			<Skeleton
				width={'100%'}
				height={40}
				style={{ position: 'absolute', bottom: 0 }}
			/>
		</div>
	)
}
