import { FC } from 'react'

import logo from 'shared/assets/icons/logo.svg'
import logoWhite from 'shared/assets/icons/logoWhite.svg'
import { Theme, useThemeContext } from 'shared/model'

interface ILogo {
	readonly className?: string
}

export const Logo: FC<ILogo> = ({ className }) => {
	const theme = useThemeContext(({ theme }) => theme)
	const logoSrc = theme === Theme.DARK ? logo : logoWhite
	return <img src={logoSrc} alt="Логотип" className={className} />
}
