import { FC } from 'react'

import { ReactComponent as InfoSvg } from 'shared/assets/icons/info.svg'

interface IInfoIcon {
	readonly className?: string
}

export const InfoIcon: FC<IInfoIcon> = ({ className }) => {
	return <InfoSvg className={className} />
}
