import clsx from 'clsx'
import { FC } from 'react'

import defaultAvt from 'shared/assets/fetisovaDefault.jpg'
import {
	BiometryIcon,
	DropIcon,
	HighBowlIcon,
	LocationIcon,
	ParkingIcon,
	PhoneIcon,
	TelegramFillIcon,
} from 'shared/icons'
import { WhatsAppIcon } from 'shared/icons/whatsAppIcon'
import { useResize } from 'shared/lib/useResize'
import { IClassById } from 'shared/types'
import { IconTitle } from 'shared/ui/iconTitle'

import './workoutFeatures.scss'

interface IWorkoutDetailFeatures {
	readonly workout: IClassById
	readonly isActive: boolean
}

export const WorkoutDetailFeatures: FC<IWorkoutDetailFeatures> = ({
	workout,
	isActive,
}) => {
	const TrainerAvatar = () => (
		<img src={defaultAvt} alt="Аватар тренера" draggable={false} />
	)
	const contacts = [
		{ title: '+7 (495) 000-00-00', Icon: PhoneIcon },
		{ title: '8 (800) 000-00-00', Icon: WhatsAppIcon },
		{ title: '@volleybox', Icon: TelegramFillIcon },
	]
	const { LG_SCREEN } = useResize()
	const about = [
		{ title: 'Проходная система', Icon: BiometryIcon },
		{ title: 'Есть душ', Icon: DropIcon },
		{ title: 'Есть кулер с водой', Icon: HighBowlIcon },
		{ title: 'Есть парковка', Icon: ParkingIcon },
	]
	const GroupItem: FC<{ children: React.ReactNode; title: string }> = ({
		children,
		title,
	}) => (
		<div className="workout-detail__group">
			<h6 className="workout-detail__group__title">{title}</h6>
			{children}
		</div>
	)
	return (
		<article
			className={clsx('workout-detail hide-scroll', isActive && 'active')}
		>
			<GroupItem title="Адрес">
				<IconTitle Icon={LocationIcon} title={workout.gym.address} gap={12} />
			</GroupItem>
			<GroupItem title="Тренер">
				<div className="workout-detail__trainer">
					<img
						className="workout-detail__trainer__avatar"
						src={defaultAvt}
						alt="Аватар тренера"
						draggable={false}
					/>
					<div className="workout-detail__trainer__info">
						<span>
							{workout.trainer.last_name} {workout.trainer.first_name}
						</span>
						<span>Мастер спорта международного класса</span>
						<span>Экс игрок Зенит-казань </span>
						<span>Игровой опыт 15 лет </span>
					</div>
				</div>
			</GroupItem>

			<GroupItem title="Условия">
				{about.map(({ title, Icon }) => (
					<IconTitle key={title} Icon={Icon} title={title} gap={12} />
				))}
			</GroupItem>

			<GroupItem title="Контакты">
				{contacts.map(({ title, Icon }) => (
					<IconTitle key={title} Icon={Icon} title={title} gap={12} />
				))}
			</GroupItem>
		</article>
	)
}
