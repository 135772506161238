import dayjs from 'dayjs'
import cookie from 'js-cookie'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { Input } from 'shared/input'
import { Button } from 'shared/ui/button'

interface IConfirmPhone {
	readonly phone: string
}

export const ConfirmPhone: FC<IConfirmPhone> = ({ phone }) => {
	const [pinValue, setPinValue] = useState('')
	const [activePinInput, setActivePinInput] = useState<number>(0)
	const sendedCodeTime = cookie.get('sendingCode')
	const [remainTime, setRemainTime] = useState(
		sendedCodeTime
			? dayjs(sendedCodeTime).add(1, 'minute').diff(dayjs(), 'second')
			: 0
	)
	useEffect(() => {
		const interval = setInterval(() => {
			if (remainTime > 0) {
				setRemainTime((prev) => prev - 1)
			}
			if (remainTime === 0) {
				clearInterval(interval)
			}
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [remainTime])

	useEffect(() => {
		if (pinValue.length) {
			setActivePinInput(pinValue.length)
			if (pinValue.length === 4) {
				toast.info(`Отправляем на сервер : ${pinValue}`)
			}
		} else {
			setActivePinInput(0)
		}
	}, [pinValue])

	useEffect(() => {
		const pinInput = document.getElementById(`pin-input-${activePinInput}`)
		pinInput?.focus()
	}, [activePinInput])
	type TRegisterFields = {
		first: string
		second: string
		third: string
		fourth: string
	}
	const options = ['first', 'second', 'third', 'fourth'] as const
	const { control, watch } = useForm<TRegisterFields>()
	const first = watch('first')
	const second = watch('second')
	const third = watch('third')
	const fourth = watch('fourth')
	useEffect(() => {
		setPinValue(`${first || ''}${second || ''}${third || ''}${fourth || ''}`)
	}, [first, second, third, fourth])
	useEffect(() => {
		const cb = (e: KeyboardEvent) => {
			if (e.key === 'Backspace') {
				setActivePinInput((draft) => {
					if (draft !== 0) return draft - 1
					return 0
				})
			}
		}
		document.addEventListener('keydown', cb)
		return () => document.removeEventListener('keydown', cb)
	}, [])
	return (
		<>
			<span className="sign-up__description">
				Отправили звонок на номер {phone}, введите последние 4 цифры номера, с
				которого вы получите звонок
			</span>
			<div className="sign-up__confirm">
				{Array(4)
					.fill(0)
					.map((_, index) => (
						<Controller
							name={options[index]}
							control={control}
							key={index}
							render={({ field }) => (
								<Input
									key={index}
									pinCode
									autoFocus={activePinInput === index}
									id={`pin-input-${index}`}
									maxLength={1}
									{...field}
									type="text"
									inputMode="numeric"
									autoComplete="off"
									disabled={activePinInput !== index}
								/>
							)}
						/>
					))}
			</div>
			<Button
				disabled={remainTime > 0}
				className="sign-up__send-code"
				centered
				link
				theme="link"
				onClick={() => {
					cookie.set('sendingCode', dayjs().toString(), { expires: 1 / 1440 })
					setRemainTime(60)
				}}
			>
				{remainTime > 0
					? `Отправим повторно через ${remainTime} с`
					: 'Отправить еще раз'}
			</Button>
		</>
	)
}
