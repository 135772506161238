import { FC } from 'react'

import { ReactComponent as TicketSvg } from 'shared/assets/icons/ticket.svg'

interface ITicketIcon {
	readonly className?: string
}

export const TicketIcon: FC<ITicketIcon> = ({ className }) => {
	return <TicketSvg className={className} />
}
