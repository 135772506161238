import { FC } from 'react'

import { IProduct } from 'shared/api/product/types'
import { TicketIcon } from 'shared/icons'
import { ToggleCheckbox } from 'shared/ui/toggleCheckbox'

import './joinClassInfo.scss'

interface IAvailableSubscribes {
	readonly products: IProduct[]
	readonly choosenProduct: number | null
	readonly setChoosenProduct: (id: number) => void
}
export const AvailableSubscribes: FC<IAvailableSubscribes> = ({
	products,
	choosenProduct,
	setChoosenProduct,
}) => {
	return (
		<>
			<p className="joinClassInfo-container__content__subtitle">
				У вас нет активных абонементов для записи. Выберите абонемент из списка
				для перехода к оплате
			</p>
			<ul className="joinClassInfo-container__content__list">
				{products.map((product) => (
					<li key={product.id}>
						<ToggleCheckbox
							Icon={TicketIcon}
							checked={product.id === choosenProduct}
							onClick={() => setChoosenProduct(product.id)}
						>
							<p>{product.title}</p>
						</ToggleCheckbox>
					</li>
				))}
			</ul>
		</>
	)
}
