import clsx from 'clsx'
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react'

import './input.scss'

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
	readonly pinCode?: boolean
}

const InputComponent = (props: IInput, ref: ForwardedRef<HTMLInputElement>) => {
	const { type = 'text', className, pinCode = false, ...rest } = props
	return (
		<input
			type={type}
			ref={ref}
			{...rest}
			className={clsx(pinCode ? 'pin-input' : 'form-input', className)}
		/>
	)
}

export const Input = forwardRef(InputComponent)
