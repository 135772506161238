import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import './loaderProducts.scss'

interface ILoaderProducts {}

export const LoaderProducts: FC<ILoaderProducts> = () => {
	return (
		<div className="loader-products fade-in">
			<Skeleton height={40} className="loader-products__tabs" />
			<div className="loader-products__content">
				<Skeleton className="loader-products__item" />
				<Skeleton className="loader-products__item" />
				<Skeleton className="loader-products__item" />
			</div>
		</div>
	)
}
