import clsx from 'clsx'
import { FC } from 'react'

import './tab.scss'

interface ITab extends React.HTMLAttributes<HTMLButtonElement> {
	readonly active: boolean
}

export const Tab: FC<ITab> = ({ active, onClick, className, children }) => {
	return (
		<button
			onClick={onClick}
			className={clsx('tab', active ? 'active' : 'not-active', className)}
		>
			{children}
		</button>
	)
}
