export const parsePhoneNumber = (value: string) => {
	if (!value) return value
	if (!value[value.length - 1].match(/[0-9]/)) return value.slice(0, -1)
	switch (value.length) {
		case 1: {
			if (value !== '+') {
				return `+7 (${value}`
			}
			return value
		}
		case 2: {
			if (value !== '+7') {
				return `+7 (`
			}
			return value
		}
		case 8: {
			if (value.startsWith('+7')) {
				return `${value.substring(0, 7)}) ${value.substring(7, 8)}`
			}
			return value
		}
		case 9: {
			if (value.startsWith('+7')) {
				return value.slice(0, -2)
			}
			return value
		}
		case 11: {
			if (value.startsWith('8') || value.startsWith('7')) {
				return `+7 (${value.substring(1, 4)}) ${value.substring(
					4,
					7
				)}-${value.substring(7, 9)}-${value.substring(9, 11)}`
			}
			return value
		}
		case 12: {
			if (value.startsWith('+7') && !value.includes('(')) {
				console.log('here')
				return `${value.substring(0, 12)}-${value.substring(
					12,
					14
				)}-${value.substring(14, 16)}`
			}
			return value
		}
		case 13: {
			if (value.startsWith('+7')) {
				if (value[12] === '-') return value.substring(0, 12)
				return `${value.substring(0, 12)}-${value.substring(12, 13)}`
			}
			return value
		}
		case 16: {
			if (value.startsWith('+7')) {
				if (value[15] === '-') return value.substring(0, 15)
				return `${value.substring(0, 15)}-${value.substring(15, 16)}`
			}
			return value
		}
		default:
			return value
	}
}
