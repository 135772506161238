import clsx from 'clsx'
import { FC } from 'react'

import { Carousel } from 'widgets/carousel'

import { useResize } from 'shared/lib/useResize'
import { IClassById } from 'shared/types'

import './workoutPhoto.scss'

interface IWorkoutPhoto {
	readonly workout: IClassById
	readonly isActive: boolean
}

export const WorkoutPhoto: FC<IWorkoutPhoto> = ({ workout, isActive }) => {
	const { LG_SCREEN } = useResize()
	return (
		<article
			className={clsx('workout-detail-photo hide-scroll', isActive && 'active')}
		>
			<Carousel size={LG_SCREEN ? 200 : 350} />
			{/* <iframe
				className="workout-detail__video"
				src="https://vk.com/video_ext.php?oid=-226961816&id=456239081"
				width="100%"
				height="388"
				allow="encrypted-media; fullscreen; picture-in-picture;"
				allowFullScreen
				title="Тренировка"
			/> */}
			<div>
				<h3 style={{ paddingBottom: '20px', fontSize: '28px' }}>Видео</h3>
				<figure id="video-container">
					<video
						id="video"
						width="100%"
						height="500px"
						preload="metadata"
						controls
						title="Тренировка"
					>
						<source src="/videos/test.webm" type="video/webm" />
					</video>
				</figure>
			</div>
		</article>
	)
}
