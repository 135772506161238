import clsx from 'clsx'
import { FC } from 'react'

import './progressBar.scss'

interface IProgressBar {
	readonly current: number
	readonly max: number
	readonly height: number
	readonly className?: string
}

export const ProgressBar: FC<IProgressBar> = ({
	current,
	max,
	height,
	className,
}) => {
	const percentage = (current / max) * 100
	return (
		<div className={clsx('progress-bar', className)} style={{ height }}>
			<div className="progress-bar__line" />
			<div
				style={{ width: `${percentage.toFixed(0)}%` }}
				className="progress-bar__wrapper"
			/>
			<p className="progress-bar__count">
				{current} из {max}
			</p>
		</div>
	)
}
