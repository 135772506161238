import {
	UseMutateFunction,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'

import {
	IJoinClass,
	joinClass,
	leaveClass,
	preJoinClass,
} from 'shared/api/class'
import { getPayUrl } from 'shared/api/pay'
import { getProductsForClass } from 'shared/api/product'
import { clearSession } from 'shared/lib/auth/sessions'
import {
	handleErrorMessage,
	handleErrorStatus,
	toastError,
} from 'shared/lib/handleError'
import { isSubscribeAvailableForClass } from 'shared/lib/utils'
import { useScheduleStore, useSessionStore } from 'shared/model'
import { IPlayer, IRoles } from 'shared/types/classTypes'

import { useJoinClassStore } from '../model/join-class-store'

export const usePreJoinClassMutate = (
	classId: number,
	classDate: string,
	onModalOpen?: () => void,
	mutateJoinClass?: UseMutateFunction<
		IJoinClass,
		Error,
		{
			subscribeId: number
			role?: keyof IRoles
		},
		unknown
	>
) => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const {
		setSubscribesForClass,
		setUserSubscribes,
		setErrorMessage,
		setIsChooseRoleStep,
	} = useJoinClassStore(
		({
			setErrorMessage,
			setSubscribesForClass,
			setUserSubscribes,
			setIsChooseRoleStep,
		}) => ({
			setErrorMessage,
			setSubscribesForClass,
			setUserSubscribes,
			setIsChooseRoleStep,
		})
	)
	return useMutation({
		mutationKey: ['preJoinClass', classId, dayjs().format('HH:mm')],
		mutationFn: (variables: {
			classId: number
			is_role_mode: boolean
			delayed_join?: boolean
			role?: keyof IRoles
		}) => preJoinClass(variables.classId),
		onSuccess: async (data, { classId, delayed_join, role, is_role_mode }) => {
			const availableSubscribes = data.filter(
				(sub) =>
					!sub.freeze && isSubscribeAvailableForClass(classDate, sub.date_end)
			)
			if (is_role_mode) {
				setIsChooseRoleStep(true)
			}
			const hasAvailableSubscribe = availableSubscribes.length > 0
			if (hasAvailableSubscribe) {
				setUserSubscribes(data)
			}
			if (delayed_join && mutateJoinClass) {
				const subscribeId = data[data.length - 1].id
				mutateJoinClass({ subscribeId, role })
				return
			}
			if (
				availableSubscribes.length === 1 &&
				!is_role_mode &&
				mutateJoinClass
			) {
				const [subscribe] = availableSubscribes
				mutateJoinClass({ subscribeId: subscribe.id, role })
				return
			}

			if (!hasAvailableSubscribe) {
				const subscribesForClass = await getProductsForClass(classId)
				if (subscribesForClass) {
					setSubscribesForClass(subscribesForClass)
				}
			}
			if (onModalOpen) onModalOpen()
		},
		onError: async (error) => {
			if (handleErrorStatus(error) === 401) {
				clearSession()
				setIsPopupAccountOpen(true)
				return
			}
			/** TODO: Если абонементы отсутствуют */
			if (
				handleErrorMessage(error) ===
				'Для записи на тренировку купите подходящий абонемент.'
			) {
				const availableSubscribes = await getProductsForClass(classId)
				if (availableSubscribes) {
					setSubscribesForClass(availableSubscribes)
					if (onModalOpen) onModalOpen()
					return
				}
			}
			/** TODO: Ошибки записи в неподходящую группу */
			if (handleErrorStatus(error) === 403) {
				setErrorMessage(handleErrorMessage(error))
				if (onModalOpen) onModalOpen()
				return
			}
			toastError(error, 'Не удалось записаться на тренировку')
		},
	})
}

export const useJoinClassMutate = (
	classId: number,
	setPlayersCount: (value: React.SetStateAction<number>) => void,
	setJoined: (value: React.SetStateAction<boolean>) => void,
	onCloseModal?: () => void,
	setPlayers?: React.Dispatch<React.SetStateAction<IPlayer[]>>
) => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const session = useSessionStore((state) => state.session)
	const resetStore = useJoinClassStore((state) => state.reset)
	const requestParams = useScheduleStore((state) => state.requestParams)
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['joinClass', classId],
		mutationFn: (variables: { subscribeId: number; role?: keyof IRoles }) =>
			joinClass(classId, variables.subscribeId, variables.role),
		onSuccess: (data, { role }) => {
			setPlayersCount(data.players_count)
			setJoined(true)
			queryClient.refetchQueries({ queryKey: ['userProfile', session?.id] })
			queryClient.refetchQueries({
				queryKey: [requestParams, session?.id || 'guest'],
			})
			queryClient.refetchQueries({ queryKey: ['workout', classId] })
			resetStore()
			if (setPlayers && session) {
				const player = {
					id: session.id,
					first_name: session.first_name,
					last_name: session.last_name,
					role: role ? role : null,
				} as IPlayer
				setPlayers((prev) => [player, ...prev])
			}
			if (onCloseModal) {
				onCloseModal()
			}
		},
		onError: (error) => {
			if (handleErrorStatus(error) === 401) {
				clearSession()
				setIsPopupAccountOpen(true)
				return
			}
			toastError(error)
		},
	})
}

export const useRedirectToPayMutate = (
	classId: number,
	role: keyof IRoles | null
) => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	return useMutation({
		mutationKey: ['redirectToPay', classId],
		mutationFn: (productId: number) => getPayUrl(productId),
		onSuccess: (data) => {
			const delayed_data = {
				classId,
				role,
			}
			sessionStorage.setItem(
				'delayed_appointment',
				JSON.stringify(delayed_data)
			)
			window.location.href = data.url
		},
		onError: (error) => {
			if (handleErrorStatus(error) === 401) {
				setIsPopupAccountOpen(true)
				return
			}
			toastError(error)
		},
	})
}

export const useLeaveClassMutate = (
	classId: number,
	setPlayersCount: (value: React.SetStateAction<number>) => void,
	setJoined: (value: React.SetStateAction<boolean>) => void,
	setIsConfirmMode?: (value: React.SetStateAction<boolean>) => void,
	setPlayers?: React.Dispatch<React.SetStateAction<IPlayer[]>>
) => {
	const setErrorMessage = useJoinClassStore((state) => state.setErrorMessage)
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const requestParams = useScheduleStore((state) => state.requestParams)
	const session = useSessionStore((state) => state.session)
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['leave_class', classId],
		mutationFn: leaveClass,
		onSuccess: (data) => {
			// if (!data.free) {
			// 	setErrorMessage(data.message)
			// }
			if (setIsConfirmMode) setIsConfirmMode(false)
			if (setPlayers && session) {
				setPlayers((prev) => prev.filter((player) => player.id !== session.id))
			}
			queryClient.refetchQueries({ queryKey: ['userProfile', session?.id] })
			setPlayersCount(data.players_count)
			queryClient.refetchQueries({
				queryKey: [requestParams, session?.id || 'guest'],
			})
			queryClient.refetchQueries({ queryKey: ['workout', classId] })
			setJoined(false)
		},
		onError: (error) => {
			toastError(error)
			// Logout user
			if (handleErrorStatus(error) === 401) {
				clearSession()
				setJoined(false)
				setIsPopupAccountOpen(true) // Open login popup
			}
		},
	})
}
