import { FC } from 'react'

import { IRoles } from 'shared/types/classTypes'
import { ToggleCheckbox } from 'shared/ui/toggleCheckbox'

import './joinClassInfo.scss'

interface IRolesList {
	readonly roles: IRoles
	readonly choosenRole: keyof IRoles | null
	readonly setChoosenRole: (role: keyof IRoles) => void
}
export const RolesList: FC<IRolesList> = ({
	roles,
	choosenRole,
	setChoosenRole,
}) => {
	const rolesArray = Object.entries(roles) as [`${keyof IRoles}`, number[]][]
	return (
		<>
			<p className="joinClassInfo-container__content__subtitle">
				Выберите <span className="green-highlight">амплуа</span>
			</p>
			<ul className="joinClassInfo-container__content__list">
				{rolesArray.map(([role, [free, count]]) => (
					<li key={role}>
						<ToggleCheckbox
							checked={role === choosenRole}
							disabled={free === 0}
							onClick={() => setChoosenRole(role)}
						>
							<div className="joinClassInfo-container__content__list__role">
								<span>{role.split(' ')[0]}</span>
								<span>{`${count - free}/${count}`}</span>
							</div>
						</ToggleCheckbox>
					</li>
				))}
			</ul>
		</>
	)
}
