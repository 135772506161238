import clsx from 'clsx'
import { CSSProperties, FC } from 'react'

import { LevelIcon } from 'shared/icons'

import './levelInfo.scss'

interface ILevelInfo {
	readonly levelTitle: string
	readonly levelColor: string
	readonly is_changed_level: boolean
	readonly className?: string
}

export const LevelInfo: FC<ILevelInfo> = ({
	levelTitle,
	levelColor,
	is_changed_level,
	className,
}) => {
	return (
		<div
			style={{ '--level-color': levelColor } as CSSProperties}
			className={clsx('level-info', className)}
		>
			<LevelIcon className={clsx('level-info__icon')} />
			<span
				className={clsx(
					'level-info__title',
					is_changed_level && 'red-highlight',
					'ellipsis'
				)}
			>
				{levelTitle}
			</span>
		</div>
	)
}
