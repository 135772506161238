import clsx from 'clsx'
import { FC, TouchEvent, useEffect, useRef, useState } from 'react'

import { NotificationItem } from 'entities/notificationItem'

import { CloseButton } from 'shared/ui/closeButton'

import './notifications.scss'

interface INotifications {
	readonly fakeData: {
		group: 'change' | 'subscribe' | 'workout'
		title: string
		message: string
	}[]
	readonly isNotificationsOpen: boolean
	readonly handleClose: () => void
}

export const Notifications: FC<INotifications> = ({
	fakeData,
	isNotificationsOpen,
	handleClose,
}) => {
	const hasTouch = matchMedia('(hover: none)').matches
	const [touchPosition, setTouchPosition] = useState<number | null>(null)
	const notificationsRef = useRef<HTMLDivElement>(null)
	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch) return
		const touchDown = e.touches[0].clientY
		setTouchPosition(touchDown)
	}
	useEffect(() => {
		if (isNotificationsOpen) {
			notificationsRef.current?.scrollTo(0, 0)
		}
	}, [isNotificationsOpen])
	const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch || touchPosition === null) return
		if (window.innerHeight - touchPosition > 30) return
		const currentPosition = e.touches[0].clientY
		const diff = touchPosition - currentPosition
		if (diff > 50) {
			handleClose()
			return
		}
	}
	return (
		<>
			<aside
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				className={clsx('notifications', isNotificationsOpen && 'active')}
			>
				<h3 className="notifications__title">Уведомления</h3>
				<section ref={notificationsRef} className="notifications__content">
					{fakeData.map((notification, idx) => (
						<NotificationItem notification={notification} key={idx} />
					))}
				</section>
				<CloseButton onClick={handleClose} className="close-btn" />
			</aside>
			<div
				onClick={handleClose}
				className={clsx(
					'notifications-overlay',
					isNotificationsOpen && 'active'
				)}
			/>
		</>
	)
}
