import { getAllProductsOptions } from './api/groupProducts'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { ProductCard } from 'entities/productCard'

import { IGroupProduct } from 'shared/api/product/types'
import { useSessionStore } from 'shared/model'
import { LoaderProducts } from 'shared/ui/loaders'
import { Tab } from 'shared/ui/tab'

import './PaymentPage.scss'

const PaymentPage = () => {
	const session = useSessionStore((state) => state.session)
	const [userId, setUserId] = useState<number | 'guest'>(session?.id ?? 'guest')
	const [activeTab, setActiveTab] = useState<number>(0)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [activeTab])

	useEffect(() => {
		const user = session?.id ?? 'guest'
		if (user !== userId) setUserId(user)
	}, [session, userId])

	const { data: products, isFetching } = useQuery(getAllProductsOptions(userId))
	const handleClick = (index: number) => {
		if (index === activeTab) return
		setActiveTab(index)
	}
	const filterProducts = (products: IGroupProduct[]) => {
		return products.filter((product) => product.is_published)
	}
	const getActiveProduct = (products: IGroupProduct[]) => {
		return products[activeTab].products
	}
	return (
		<div className="products">
			{isFetching && <LoaderProducts />}
			{products && !isFetching && (
				<>
					<article className="products__tabs">
						{filterProducts(products).map(({ id, title }, idx) => (
							<Tab
								active={idx === activeTab}
								onClick={() => handleClick(idx)}
								key={id}
							>
								{title}
							</Tab>
						))}
					</article>
					<section className="products__content">
						<ul className="products__list">
							{getActiveProduct(products).map((product) => (
								<li key={product.id}>
									<ProductCard product={product} />
								</li>
							))}
						</ul>
					</section>
				</>
			)}
		</div>
	)
}

export default PaymentPage
