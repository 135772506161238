import clsx from 'clsx'
import { FC } from 'react'

import { PlayersSquad } from 'widgets/playersSquad'

import { IClassById, IPlayer } from 'shared/types'
import { ProgressBar } from 'shared/ui/progressBar'

import './workoutSquad.scss'

interface IWorkoutSquad {
	readonly workout: IClassById
	readonly isActive: boolean
}

export const WorkoutSquad: FC<IWorkoutSquad> = ({ workout, isActive }) => {
	const players = [
		...workout.players,
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9997 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9998 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9996 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9995 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9994 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9993 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9992 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9991 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9989 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9990 },
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9988 },
	] as IPlayer[]

	return (
		<article
			className={clsx('workout-squad hide-scroll', isActive && 'active')}
		>
			<div className="workout-squad__progress">
				<ProgressBar current={players.length} max={workout.limit} height={40} />
			</div>
			<PlayersSquad players={players} />
		</article>
	)
}
