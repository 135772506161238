import { FC } from 'react'

import './notificationItem.scss'

interface INotificationItem {
	readonly notification: {
		group: 'change' | 'subscribe' | 'workout'
		title: string
		message: string
	}
}

export const NotificationItem: FC<INotificationItem> = ({ notification }) => {
	return (
		<article className="notification-item">
			<p data-type={notification.group} className="notification-item__title">
				{notification.title}
			</p>
			<p>{notification.message}</p>
		</article>
	)
}
