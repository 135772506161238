import { BackdropProps } from '@mui/material'
import clsx from 'clsx'
import { FC, InputHTMLAttributes, useState } from 'react'
import { Control, Controller, ValidationRule } from 'react-hook-form'

import { EyeIcon } from 'shared/icons'
import { Input } from 'shared/input'

import './field.scss'

interface IField extends InputHTMLAttributes<HTMLInputElement> {
	readonly control: Control<any>
	readonly label: string
	readonly error?: string | null
	readonly controlPattern?: ValidationRule<RegExp> | undefined
	readonly validate?: (value: string) => boolean | string
	readonly name: string
}

export const Field: FC<IField> = ({
	control,
	name,
	placeholder,
	required = false,
	label,
	type = 'text',
	error,
	className,
	controlPattern,
	validate,
	...rest
}) => {
	const [isPasswordShown, setIsPasswordShown] = useState(false)
	return (
		<Controller
			name={name}
			control={control}
			rules={{
				pattern: controlPattern,
				validate: validate ? (value) => validate(value) : undefined,
			}}
			render={({ field }) => (
				<div className={clsx('field-control', className)}>
					<label className="field-control__label">
						<input
							id={name}
							type={isPasswordShown ? 'text' : type}
							className="field-control__input"
							placeholder={placeholder}
							required={required}
							aria-invalid={error ? 'true' : 'false'}
							{...rest}
							{...field}
						/>
						<div className={clsx('label', error && 'red-highlight')}>
							{error ? error : label}
						</div>
						{type === 'password' && (
							<button
								type="button"
								className="field-control__eye"
								tabIndex={-1}
								aria-label={
									isPasswordShown ? 'Скрыть пароль' : 'Показать пароль'
								}
								onClick={(e) => {
									e.stopPropagation()
									setIsPasswordShown(!isPasswordShown)
								}}
							>
								<EyeIcon shown={isPasswordShown} />
							</button>
						)}
					</label>
				</div>
			)}
		/>
	)
}
