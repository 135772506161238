import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import './loaderFilter.scss'

interface ILoaderFilter {}

export const LoaderFilter: FC<ILoaderFilter> = () => {
	return (
		<div className="loader-filter">
			{Array(3)
				.fill(0)
				.map(() => (
					<>
						<Skeleton height={30} className="loader-filter__title" />
						<div className="loader-filter__group">
							<Skeleton height={50} className="loader-filter__item" />
							<Skeleton height={50} className="loader-filter__item" />
							<Skeleton height={50} className="loader-filter__item" />
						</div>
					</>
				))}
		</div>
	)
}
