import clsx from 'clsx'
import {useState} from 'react'
import {useEffect} from 'react'
// Components
// Context
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {toast} from 'sonner'

import {PopupRegister} from 'widgets/popupRegister'

import {getUserProfileData, login} from 'shared/api/account'
import {IAuthFields} from 'shared/api/account/types'
import {STORE_USER_DATA_NAME} from 'shared/constants'
import {createSession} from 'shared/lib/auth/sessions'
import {handleErrorStatus, toastError} from 'shared/lib/handleError'
import {useSessionStore} from 'shared/model'
import {TPopupAccountMode} from 'shared/types'
import {Button} from 'shared/ui/button'
import {Field} from 'shared/ui/field'
import {Modal} from 'shared/ui/modal'

// Files
import './PopupAccount.scss'
import {apiHostName} from "../../../constants/api";

declare global {
    interface Window {
        onTelegramAuth: (user: { id: number; first_name: string; last_name: string; username?: string }) => void
    }
}

interface TelegramUser {
    auth_date?: number;
    first_name?: string;
    hash?: string;
    id: number;
    last_name: string;
    photo_url?: string;
    username?: string;
}

export const PopupAccount = () => {
    const navigate = useNavigate()
    const [mode, setMode] = useState<TPopupAccountMode>('login')
    const {isPopupAccountOpen, setIsPopupAccountOpen} = useSessionStore(
        ({isPopupAccountOpen, setIsPopupAccountOpen}) => ({
            isPopupAccountOpen,
            setIsPopupAccountOpen,
        })
    )
    /*** Handlers ***/
    const handleClosePopup = () => setIsPopupAccountOpen(!isPopupAccountOpen)
    const {session, setSession} = useSessionStore(
        ({session, setSession}) => ({session, setSession})
    )

    useEffect(() => {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://telegram.org/js/telegram-widget.js?22'
        script.setAttribute('data-telegram-login', 'kovdmit_bot')
        script.setAttribute('data-size', 'large')
        script.setAttribute('data-onauth', 'onTelegramAuth(user)')
        script.setAttribute('data-request-access', 'write')

        document.getElementById('telegram-login-container')?.appendChild(script)

        async function sendTelegramAuthData(user: TelegramUser) {
            try {
                const response = await fetch(`${apiHostName}/api/v2/telegram_auth/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        auth_date: user.auth_date,
                        first_name: user.first_name,
                        hash: user.hash,
                        id: user.id,
                        last_name: user.last_name,
                        photo_url: user.photo_url,
                        username: user.username
                    })
                });

                if (!response.ok) {
                    throw new Error(`Ошибка: ${response.statusText}`);
                } else {
                    const responseData = await response.json();
                    const tokens = {
                        access: responseData.access,
                        refresh: responseData.refresh,
                    }
                    createSession(tokens)
                    setIsPopupAccountOpen(!isPopupAccountOpen)
                    const user = await getUserProfileData()
                    setSession(user)
                }

            } catch (error) {
                console.error('Ошибка авторизации через Telegram:', error);
            }
        }

        window.onTelegramAuth = (user) => {
            console.log('Пользователь авторизован через Telegram:', user);
            sendTelegramAuthData(user);
        };

        return () => {
            script.remove()
        }
    }, [])

    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
    } = useForm<IAuthFields>()
    const onSubmit = async (values: IAuthFields) => {
        try {
            const tokens = await login(values)
            createSession(tokens)
            setIsPopupAccountOpen(!isPopupAccountOpen)
            const user = await getUserProfileData()
            localStorage.setItem(
                STORE_USER_DATA_NAME,
                JSON.stringify({
                    id: user.id,
                    sex: user.sex,
                    first_name: user.first_name,
                    last_name: user.last_name,
                })
            )
            setSession(user)
        } catch (e) {
            if (handleErrorStatus(e) === 401) {
                toast.error('Неверный логин или пароль, попробуйте снова')
            } else {
                toastError(e)
            }
        }
    }
    const getModalTitle = () => {
        switch (mode) {
            case 'login':
                return 'Вход'
            case 'register':
                return 'Регистрация'
            case 'phone-confirm':
                return 'Подтверждение номера'
        }
    }
    const handleChangeMode = (mode: TPopupAccountMode) => {
        setMode(mode)
    }
    const renderLoginForm = () => (
        <div className={clsx('popup-account')} id="login-container">
            <form
                className="popup-account__form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="on"
            >
                <Field
                    control={control}
                    name="username"
                    label="Логин"
                    autoFocus
                    error={errors.username?.message}
                    required
                    placeholder="Логин"
                />
                <Field
                    control={control}
                    name="password"
                    type="password"
                    label="Пароль"
                    error={errors.password?.message}
                    required
                    placeholder="Пароль"
                />

                <Button
                    theme="yellow"
                    type="submit"
                    centered
                    bold
                    className="popup-account__form__btn"
                >
                    Войти
                </Button>
                <Button
                    centered
                    link
                    theme="link"
                    onClick={() => handleChangeMode('register')}
                >
                    Забыли пароль?
                </Button>
                <Button
                    centered
                    link
                    theme="link"
                    onClick={() => handleChangeMode('register')}
                >
                    У меня нет аккаунта
                </Button>
                <div id="telegram-login-container"></div>
            </form>
        </div>
    )
    return (
        <Modal
            className="popup-account-modal"
            onClose={handleClosePopup}
            closeButton
            isHighPriority
            title={getModalTitle()}
        >
            {mode === 'login' ? (
                renderLoginForm()
            ) : (
                <PopupRegister onChangeMode={handleChangeMode} mode={mode}/>
            )}
        </Modal>
    )
}
