import { ConfirmPhone } from './confirmPhone'
import clsx from 'clsx'
import dayjs from 'dayjs'
import cookie from 'js-cookie'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { IRegisterFields } from 'shared/api/account'
import { InfoIcon } from 'shared/icons'
import { toastError } from 'shared/lib/handleError'
import { parsePhoneNumber } from 'shared/lib/utils'
import { TPopupAccountMode } from 'shared/types'
import { Button } from 'shared/ui/button'
import { Checkbox } from 'shared/ui/checkbox'
import { Field } from 'shared/ui/field'

import './popupRegister.scss'

interface IPopupRegister {
	readonly onChangeMode: (mode: TPopupAccountMode) => void
	readonly mode: TPopupAccountMode
}

export const PopupRegister: FC<IPopupRegister> = ({ onChangeMode, mode }) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
	} = useForm<IRegisterFields>({ mode: 'onTouched' })

	const onSubmit = async (values: IRegisterFields) => {
		try {
			onChangeMode('phone-confirm')
			const now = dayjs().toString()
			cookie.set('sendingCode', now, { expires: 1 / 1440 })
		} catch (e) {
			toastError(e)
		}
	}

	const renderFieldError = (message?: string) => (
		<p className={clsx('sign-up__error red-highlight')}>
			<InfoIcon />
			{message || 'Поле обязательно для заполнения'}
		</p>
	)

	return (
		<section id="register-container" className={clsx('sign-up')}>
			{mode === 'phone-confirm' ? (
				<ConfirmPhone phone={watch('phone')} />
			) : (
				<>
					<form
						className="sign-up__form"
						onSubmit={handleSubmit(onSubmit)}
						autoComplete="on"
					>
						<Field
							control={control}
							name="phone"
							type="tel"
							autoFocus
							label="Номер телефона"
							error={errors.phone?.message}
							autoComplete="tel"
							inputMode="tel"
							maxLength={18}
							controlPattern={{
								value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
								message: 'Введите действительный номер телефона',
							}}
							value={parsePhoneNumber(watch('phone'))}
							onInput={(e) => {
								e.currentTarget.value = parsePhoneNumber(e.currentTarget.value)
							}}
							required={false}
							placeholder="Номер телефона"
						/>
						<Field
							control={control}
							name="password"
							type="password"
							label="Пароль"
							error={errors.password?.message}
							autoComplete="new-password"
							controlPattern={{
								value: /^.{8,}$/,
								message: '8 и более символов',
							}}
							required={false}
							placeholder="Пароль"
						/>
						<Field
							control={control}
							name="password_confirmation"
							type="password"
							label="Подтвердите пароль"
							autoComplete="new-password"
							validate={(value) =>
								value === watch('password') || 'Пароли не совпадают'
							}
							error={errors.password_confirmation?.message}
							required={false}
							placeholder="Подтвердите пароль"
						/>
						<Controller
							name="policy"
							control={control}
							rules={{
								validate: (value) =>
									value || 'Ознакомьтесь и примите политику конфиденциальности',
							}}
							render={({ field }) => (
								<div className="sign-up__form-control">
									<Checkbox
										{...field}
										value={field.value?.toString()}
										checked={field.value}
										label={
											<span>
												Я даю согласие на обработку{' '}
												<Link
													className="sign-up__policy-link"
													to="/page/policy"
												>
													персональных данных
												</Link>
											</span>
										}
										aria-invalid={errors.policy ? 'true' : 'false'}
									/>
									{errors.policy && renderFieldError(errors.policy.message)}
								</div>
							)}
						/>
						<Button type="submit" theme="yellow" className="sign-up__btn">
							Зарегистрироваться
						</Button>
						<Button
							link
							theme="link"
							type="button"
							onClick={() => onChangeMode('login')}
						>
							У меня есть аккаунт
						</Button>
					</form>
				</>
			)}
		</section>
	)
}
