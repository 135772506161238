import { FC } from 'react'

import { ReactComponent as MetroLocationSvg } from 'shared/assets/icons/metroLocation.svg'

interface IMetroLocationIcon {
	readonly className?: string
}

export const MetroLocationIcon: FC<IMetroLocationIcon> = ({ className }) => {
	return <MetroLocationSvg className={className} />
}
