import clsx from 'clsx'
import { FC } from 'react'

import { MetroIcon, MetroLocationIcon } from 'shared/icons'

import './metro.scss'

interface IClassGym {
	title: string
	className?: string
	color?: 'white' | 'gray'
}

export const Metro: FC<IClassGym> = ({ title, className, color = 'white' }) => {
	return (
		<div className={clsx('metro', className)} data-color={color}>
			<MetroLocationIcon />
			<span className="ellipsis">{title}</span>
		</div>
	)
}
