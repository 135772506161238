import { FC } from 'react'
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form'
import Select from 'react-select'

import { ProfileCard } from 'entities/profileCard'

import { ISettingFields } from 'shared/api/account'
import { ROLES } from 'shared/constants'
import { Checkbox } from 'shared/ui/checkbox'
import { Field } from 'shared/ui/field'

import './mainInfoSettings.scss'

interface IMainInfoSettings {
	readonly control: Control<ISettingFields>
	readonly errors: FieldErrors<ISettingFields>
	readonly watch: UseFormWatch<ISettingFields>
}
export const MainInfoSettings: FC<IMainInfoSettings> = ({
	control,
	errors,
	watch,
}) => {
	const options = ROLES.map(({ name, id }) => ({ label: name, value: id }))
	return (
		<article className="main-info-settings">
			<ProfileCard>
				<h3 className="main-info-settings__title">Об игроке</h3>
				<Field
					name="first_name"
					controlPattern={{
						value: /^[А-Яа-яЁё]+$/,
						message: 'Разрешены только русские буквы',
					}}
					label="Имя"
					control={control}
					required
					placeholder="Ваше имя"
					error={errors.first_name?.message}
				/>
				<Controller
					name="show_my_name"
					control={control}
					render={({ field }) => (
						<Checkbox
							{...field}
							label="Показывать мое имя в списках участников"
							checked={field.value}
							value={'show_my_name'}
						/>
					)}
				/>
				<Field
					name="last_name"
					label="Фамилия"
					controlPattern={{
						value: /^[А-Яа-яЁё]+$/,
						message: 'Разрешены только русские буквы',
					}}
					control={control}
					required
					placeholder="Ваша фамилия"
					error={errors.last_name?.message}
				/>
				<Field
					name="patronymic"
					label="Отчество"
					controlPattern={{
						value: /^[А-Яа-яЁё]+$/,
						message: 'Разрешены только русские буквы',
					}}
					control={control}
					placeholder="Ваше отчество"
					required
					error={errors.patronymic?.message}
				/>
				<Field
					name="birthday"
					label="Дата рождения"
					control={control}
					type="date"
					placeholder="дд.мм.гггг"
					required
					error={errors.birthday?.message}
				/>
				<Controller
					name="show_my_birthday"
					control={control}
					render={({ field }) => (
						<Checkbox
							{...field}
							label="Показывать мою дату рождения"
							checked={field.value}
							value={'show_my_birthday'}
						/>
					)}
				/>
				<Field
					name="height"
					label="Рост"
					control={control}
					placeholder="Рост, см"
					inputMode="numeric"
					maxLength={3}
					onInput={(val) =>
						(val.currentTarget.value = val.currentTarget.value.replace(
							/[^\d]/g,
							''
						))
					}
					required
					error={errors.height?.message}
				/>
				<Field
					name="experience"
					label="Опыт игры"
					control={control}
					placeholder="Опыт, лет"
					maxLength={2}
					inputMode="numeric"
					onInput={(val) =>
						(val.currentTarget.value = val.currentTarget.value.replace(
							/[^\d]/g,
							''
						))
					}
					required
					error={errors.experience?.message}
				/>
				<Controller
					name="role"
					control={control}
					render={({ field }) => (
						<div className="main-info-settings__control">
							<label htmlFor="role">Ваше амплуа</label>
							<Select
								id="role"
								placeholder="Амплуа"
								isMulti
								isOptionDisabled={() => watch('role').length >= 2}
								className="main-info-settings__control-select"
								classNamePrefix={'main-info-settings__control-select'}
								{...field}
								options={options}
							/>
						</div>
					)}
				/>
			</ProfileCard>
		</article>
	)
}
