import { FC } from 'react'

import { ReactComponent as ClosedEyeSvg } from 'shared/assets/icons/closedEye.svg'
import { ReactComponent as OpenEyeSvg } from 'shared/assets/icons/openEye.svg'

interface IEyeIcon {
	readonly shown: boolean
	readonly className?: string
}

export const EyeIcon: FC<IEyeIcon> = ({ className, shown }) => {
	return shown ? (
		<ClosedEyeSvg className={className} />
	) : (
		<OpenEyeSvg className={className} />
	)
}
